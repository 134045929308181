export const getConfig = (id) => {
  const options = {
    only: [
      'id',
      'act_type_id',
      'act_no',
      'act_date',
      'eliminated',
      'elimination_date',
      'agpz',
      'aghk',
      'stage',
      'subproject_id',
      'vehicle_id',
      'contract_no',
      'contract_date',
      'check_date',
      'transfer_date',
      'week',
      'transfer_week',
      'work_object',
      'alleged_fine',
      'fine',
      'contractor_id',
      'subcontractor_id',
      'subsubcontractor_id',
      'notification_date',
      'work_name',
      'representative_contractor',
      'representative_contractor_post',
      'representative_subcontractor',
      'representative_subcontractor_post',
      'comment',
      'created_at'
    ],
    include: {
      act_type: {},
      subproject: {},
      vehicle: {},
      contractor: {},
      subcontractor: {},
      givers: {},
      act_givers: {},
      act_violations: {
        include: {
          bdd_violation: {
            only: ['id', 'description', 'norm_doc_requirements', 'no'],
            include: {
              vehicle: {
                only: ['reg_number']
              }
            }
          }
        }
      },
      representative_employees: {},
      act_representatives: {}
    }
  }
  return {
    where: [{
      field: 'id',
      op: '=',
      value: id
    }],
    ...options
  }
}

export const fields = [
  {
    title: 'Номер акта',
    model: 'act_no',
    type: 'text',
    read_only: true,
    width: 'half'
  },
  {
    title: 'Тип акта',
    model: 'act_type_id',
    type: 'select',
    read_only: true,
    select: 'act_type',
    width: 'half'
  },
  {
    title: 'Год акта',
    model: 'act_date',
    type: 'number',
    noControls: true,
    min: 1970,
    max: 3000,
    width: 'half'
  },
  {
    title: 'Срок устранения',
    type: 'datetime',
    model: 'elimination_date',
    format: 'dd.MM.yyyy',
    width: 'half'
  },
  {
    title: 'АГХК',
    type: 'checkbox',
    model: 'aghk',
    column: true,
    width: 'half'
  },
  {
    title: 'АГПЗ',
    type: 'checkbox',
    model: 'agpz',
    column: true,
    width: 'half'
  },
  {
    title: 'Стадия',
    type: 'text',
    model: 'stage',
    width: 'half'
  },
  {
    title: 'Подпроект',
    type: 'select',
    filterable: true,
    select: 'subproject',
    model: 'subproject_id',
    width: 'half'
  },
  {
    title: '№ контракта',
    type: 'text',
    model: 'contract_no',
    width: 'half'
  },
  {
    title: 'Дата контракта',
    type: 'datetime',
    model: 'contract_date',
    format: 'dd.MM.yyyy HH:mm',
    width: 'half'
  },
  {
    title: 'Дата и время проверки',
    type: 'datetime',
    model: 'check_date',
    format: 'dd.MM.yyyy HH:mm',
    width: 'half'
  },
  {
    title: 'Дата и время передачи акта',
    type: 'datetime',
    model: 'transfer_date',
    format: 'dd.MM.yyyy HH:mm',
    width: 'half'
  },
  {
    title: 'ГРЗ',
    type: 'select',
    filterable: true,
    select: 'vehicles',
    model: 'vehicle_id',
    width: 'half'
  },
  {
    title: 'Сообщить до',
    type: 'datetime',
    model: 'notification_date',
    format: 'dd.MM.yyyy HH:mm',
    width: 'half',
    only: 'assignment'
  },
  {
    title: 'Объект проведения работ',
    type: 'text',
    model: 'work_object',
    only: 'violation'
  },
  {
    title: 'Сумма предполагаемой штрафной санкции',
    type: 'number',
    noControls: true,
    model: 'alleged_fine',
    width: 'half',
    only: 'violation'
  },
  {
    title: 'Сумма штрафной санкции',
    type: 'number',
    noControls: true,
    model: 'fine',
    width: 'half',
    only: 'violation'
  },
  {
    title: 'Подрядчик',
    type: 'select',
    filterable: true,
    select: 'contractor',
    model: 'contractor_id'
  },
  {
    title: 'Субподрядчик',
    type: 'select',
    filterable: true,
    select: 'contractor',
    model: 'subcontractor_id'
  },
  {
    title: 'Субсубподрядчик',
    type: 'select',
    filterable: true,
    select: 'contractor',
    model: 'subsubcontractor_id'
  },
  {
    title: 'Наименование работ',
    type: 'text',
    model: 'work_name',
    only: 'violation'
  },
  {
    title: {
      violation: 'В присутствии представителей',
      assignment: 'Составил акт'
    },
    type: 'select',
    filterable: true,
    subtitle: 'ФИО, должность',
    select: 'employees',
    multiple: true,
    model: 'representative_employees'
  },
  {
    title: 'Акт выдал',
    type: 'select',
    filterable: true,
    subtitle: 'ФИО, должность',
    select: 'employees',
    multiple: true,
    model: 'givers'
  },
  {
    title: 'Представитель подрядчика:'
  },
  {
    title: 'ФИО',
    type: 'text',
    model: 'representative_contractor',
    width: 'half'
  },
  {
    title: 'Должность',
    type: 'text',
    model: 'representative_contractor_post',
    width: 'half'
  },
  {
    title: 'Представитель субподрядчика:'
  },
  {
    title: 'ФИО',
    type: 'text',
    model: 'representative_subcontractor',
    width: 'half'
  },
  {
    title: 'Должность',
    type: 'text',
    model: 'representative_subcontractor_post',
    width: 'half'
  },
  {
    title: 'Примечание',
    type: 'textarea',
    model: 'comment'
  }
]
